<template lang='pug'>
.page-wrap
	.property
		MapAndLive(ref='map')
		//- router-link.name(:to="{ path: '/admin/property/' + property.key + '/settings' }") {{ property.name }}
		LiveSessions(v-if='property.numLive' :live='property.live')
		//- LiveSessions(:live='testdata')
		DateControls
		RecentSessions
		.widget
			router-link(:to="{ path: '/admin/property/' + property.key + '/pages' }") Page Visits
		UniqueVisitorsChart
		UniqueVisitorsList
</template>

<script>
import Live from "../../mixins/Live";
import Test from "../../mixins/Test";

// widgets //
import MapAndLive from "../../widgets/MapAndLive";
import LiveSessions from "../../widgets/LiveSessions";
import DateControls from "../../widgets/DateControls";
import RecentSessions from "../../widgets/RecentSessions";
import UniqueVisitorsChart from "../../widgets/UniqueVisitorsChart";
import UniqueVisitorsList from "../../widgets/UniqueVisitorsList";

export default {
	mixins: [Live, Test],
	components: {
		MapAndLive,
		LiveSessions,
		DateControls,
		RecentSessions,
		UniqueVisitorsChart,
		UniqueVisitorsList
	},
	methods: {
		async getRecentVisitors() {
			let pid = this.$route.params.key;
			await fetch("/api/v1/sessions?pid=" + pid + "&groupby=ip&details=true&geo=true&past=7")
				.then((response) => response.json())
				.then((property) => {
				// sessions grouped by ip //
				this.$refs.map.setPastVisitors(property.sessions);
				});
		},
		onLiveConnectionsChanged() {
			this.$refs.map.setLiveSessions(this.property.live);
			this.getRecentVisitors();
		},
	},
	created() {
		this.init();
	},
	mounted(){
		window.scrollTo(0, 0);
	}
};
</script>

<style lang="stylus" scoped>
@import '../../assets/css/libs/fonts.styl'
@import '../../assets/css/libs/colors.styl'

.property
	width 94vw
	margin auto
	max-width 1400px

</style>
<template lang="pug">
.widget
	#ml
		gMap(ref="map")
		.box
			.lbl Live Connections
			.num {{ live.length }}
			LiveTracker
</template>

<script>

import gMap from "../map/Map";
import LiveTracker from "../charts/LiveTracker.vue"

export default{
	data(){
		return{
			live:[]
		}
	},
	components: { gMap, LiveTracker },
	mounted(){
		this.$refs.map.init();
	},
	methods:{
		setLiveSessions(live){
			this.live = live;
		},
		setPastVisitors(visitors){
			visitors = visitors.filter((visitor) => { return visitor.ip.address != 'localhost' });
			this.$refs.map.setPastVisitors(visitors);
		}
	}

}

</script>

<style lang="stylus" scoped>
#ml
	position relative
	.box
		width 150px
		position absolute
		top 0; right 0
		height 100%
		color white
		background rgba(0,0,0,.5)
		padding 20px 14px
		margin-left 2px
		font-weight bold
		.lbl
			font-size .75rem
			text-align center
			text-transform uppercase
			white-space nowrap
			margin-bottom 14px
		.num
			text-align right
			font-size 2.8rem
		.cnvs
			background-color red

</style>
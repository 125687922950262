<template lang="pug">
.widget
	h4 Live Connections
	#liveSessions
		.connection(v-for="c in live")
			p Connected: {{dateAndTime(c.date)}}
			p Duration: {{secsToMins(c.duration)}}
			p Address: {{c.ip.address || c.ip}}
			p Location: {{location(c.ip)}}
</template>

<script>

import Utils from '../mixins/Utils'

export default{

	props:{
		live:Array,
	},
	mixins: [Utils],
	// mounted(){
	// 	console.log('live = ', this.live);
	// }

}

</script>

<style lang="stylus" scoped>
#liveSessions
	text-align left
	margin-bottom 20px
	.connection
		padding 12px 16px
		line-height 1.3rem
		background white
	.connection:not(:last-child)
		margin-bottom 4px
</style>
<template lang='pug'>
.canvas-wrap
	canvas#dailySessions
</template>

<script>

import moment from 'moment'
import LineChart from '../charts/LineChart'

export default{

	data(){
		return{
			links: []
		}
	},

	mixins: [LineChart],

	async mounted(){
		this.initChart('dailySessions');
		await fetch('/api/v1/sessions?pid='+this.$route.params.key+'&groupby=date&past=7')
		.then(response => response.json())
		.then(property => {
		// show most recent data on the right side of chart //
			property.sessions.reverse();
			let labels = []
			let values = []
			property.sessions.forEach(session => {
				labels.push(this.dateNoYear(session.date));
				values.push(session.sessions);
				this.links.push(this.datePath(session.date));
			});
			this.renderChart(labels, values);
		});
	},
	methods:{
		datePath(date)
		{
			date = new Date(date);
			let y = date.getFullYear();
			let m = date.getMonth() + 1;
			let d = date.getDate();
			return '/admin/property/'+this.$route.params.key+'/'+m+'-'+d+'-'+y;
		},
 		dateNoYear(date){
			return moment(date).format('MMM D'); // 'Do' = 'th'
		},
		onChartClicked(index){
			console.log(this.links[index]);
		},
	}

}

</script>

<style lang="stylus" scoped>
.canvas-wrap
	position relative
	height 30vh
	width 100%
	margin 20px 0
</style>
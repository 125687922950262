<template lang="pug">
.widget
	h4 Unique Visitors – List
	router-link.visitor(:to="{ path: ipPath(v.ip) }")(v-if="visitors.length", v-for="v in visitors")
		.ip
			.ip {{ v.ip.address }}
			.location {{ location(v.ip) }}
		.sessions
			.total Sessions: {{ v.sessions.length }}
			.duration {{ duration(v) }}
</template>

<script>
import Utils from '../mixins/Utils';

export default{
	data() {
		return {
			visitors: Array,
		};
	},
	mixins:[Utils],
	methods:{
		ipPath(ip) {
			return "/admin/property/" + this.$route.params.key + "/ip/" + ip.address;
		}
	},
	async mounted(){
		let pid = this.$route.params.key;
		await fetch("/api/v1/sessions?pid=" + pid + "&groupby=ip&details=true&geo=true&past=7")
			.then((response) => response.json())
			.then((property) => {
				//	sort by # of sessions descending //
				property.sessions.sort((a, b) => {
					return b.sessions.length - a.sessions.length;
				});
				this.visitors = property.sessions.slice(0, 10);
			});
	}

}

</script>

<style lang="stylus" scoped>
.visitor {
	padding 10px 14px
	cursor pointer
	background white
	//border 1px solid $component_border_color
	margin-bottom 4px
	transition all 0.3s
	.ip, .sessions {
		display flex
		padding 2px 0
		justify-content space-between
	}
}
.visitor:hover {
	color white
	font-weight 500
	background #cf0000
	//border 1px solid #cf0000
}
</style>
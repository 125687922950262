
import Chart from 'chart.js/auto';
import ChartUtils from './ChartUtils'

export default {
	mixins: [ChartUtils],
	methods: {
		renderChart(labels, values, inserts) {
			if (this.chart.destroy) this.chart.destroy();

		//	https://stackoverflow.com/a/55951764/228315
			var plugin = {
				id: 'drawInlineText',
				beforeDraw: function (chart) {
				//	console.log('drawing text');
					var meta = chart.getDatasetMeta(0);
					meta.data.forEach((bar, index) => {
						chart.ctx.font = '12px Patua One';
						chart.ctx.textAlign = 'left';
						chart.ctx.fillStyle = '#777777';
						chart.ctx.textBaseline = 'middle';
						chart.ctx.fillText(inserts[index], bar.x - bar.width + 10, bar.y + 1);
					});
				}
			};

			let options = this.getChartOptions();
			options.indexAxis = 'y';
			this.chart = new Chart(this.cnvs.getContext('2d'), {
				type: 'bar',
				data: {
					labels: labels,
					datasets: [{
						data: values,
					}]
				},
				plugins: [plugin],
				options: options
			});
		}
	}

}

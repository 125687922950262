<template lang='pug'>
.widget
	h4 Most Recent Sessions
	#recentSessions
		div(v-for='s in sessions').session
			.l
				p.loc {{location(s.ip)}}
				p.ip {{s.ip.address}}
			.r
				p.time {{timeAgo(s.date)}}
				p.duration {{secsToMins(s.duration)}}

</template>

<script>

import Utils from '../mixins/Utils'

export default{

	data(){
		return{
			sessions:Array
		}
	},
	mixins: [Utils],
	async mounted(){
		await fetch('/api/v1/sessions?pid='+this.$route.params.key+'&geo=true&details=true')
		.then(response => response.json())
		.then(property => {
			this.sessions = property.sessions.splice(0, 12);
		});
	},

}

</script>

<style lang="stylus" scoped>
#recentSessions
	overflow scroll
	display flex
	flex-wrap wrap
	max-height 24vh
	.session
		display flex
		padding 8px 12px
		border 1px solid #ccc
		margin .5% 0
		border-radius 3px
		.l, .r
			flex 1
		.l
			text-align left
		.r
			text-align right
		p
			margin 6px auto
		.ip, .duration
			color red
			font-size .8rem

	@media (min-width: 1000px)
		.session
			width 32.666666666666667%
		.session:not(:nth-child(3n))
			margin-right 1%
	@media (min-width: 600px) and (max-width: 1000px)
		.session
			width 49.5%
		.session:not(:nth-child(2n))
			margin-right 1%
	@media (max-width: 600px)
		.session
			width 100%

</style>
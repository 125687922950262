
import Chart from 'chart.js/auto';
import { canvas } from 'chart.js/helpers';

export default {
	data() {
		return {
			cnvs: Object,
			chart: Chart,
		}
	},
	methods: {
		initChart(cnvsId) {
			this.cnvs = document.getElementById(cnvsId);
			this.cnvs.addEventListener('click', (e) => {
				const points = this.chart.getElementsAtEventForMode(e, 'nearest', { intersect: true }, true);
				if (points.length && this.onChartClicked) this.onChartClicked(points[0].index);
			});
		},
		getChartOptions() {
			return {
				responsive: true,
				maintainAspectRatio: false,
				animation: {
					duration: 1000
				},
				elements: {
					line: {
						tension: .4 // 0 disables bezier curves
					}
				},
				plugins: {
					legend: {
						display: false
					},
					tooltip: {
						//backgroundColor:'#eee'
					}
				},
				scales: this.getScaleOptions()
			}
		},
		getScaleOptions() {
			// https://www.chartjs.org/docs/latest/axes/styling.html#grid-line-configuration
			return {
				yAxes: {
					grid: {
						display: false
					}
				},
				xAxes: {
					grid: {
						drawTicks: false
					},
					ticks: {
						padding: 15
					}
				}
			}
		}
	}
}
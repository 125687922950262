
import Chart from 'chart.js/auto';
import ChartUtils from './ChartUtils'

export default{
	mixins: [ChartUtils],
	methods:{
		renderChart(labels, values){
			if (this.chart.destroy) this.chart.destroy();
			this.chart = new Chart(this.cnvs.getContext('2d'), {
				type: 'line',
				data: {
					labels: labels,
					datasets: [{
						data: values,
					// lines //
						borderWidth: 2,
						borderColor: 'rgb(204, 134, 80)',
					// points //
						pointRadius: 4,
						pointBackgroundColor: 'rgb(204, 134, 80)',
						pointHoverRadius: 10,
					// bkgd fill //
						fill: true,
						backgroundColor: 'rgba(204, 134, 80, .2)'
					}]
				},
				options: this.getChartOptions()
			});
		}
	}

}
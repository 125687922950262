<template lang="pug">
.widget
	h4 Traffic – {{ranges[selectedRange]}}
	#date-controls
		.row
			#sdate.picker
				label Start Date
				input(readonly)
			#edate.picker
				label End Date
				input(readonly)
		.row
			select(@change='onRangeSelected' ref='rangeSelect' v-model='selectedRange')
				option(v-for="(value, name) in ranges" v-bind:value="name") {{value}}
			button Update
	DailySessions
</template>

<script>

import Pikaday from "pikaday";
import "pikaday/css/pikaday.css";
import DailySessions from "./DailySessions";

export default{

	data(){
		return{
			ranges:{
				p24 : 'Past 24 Hours',
				p7d : 'Past 7 Days',
				p4w : 'Past 4 Weeks',
				p6m : 'Past 6 Months',
				cst : 'Custom Date Range'
			},
			selectedRange:String
		}
	},
	components:{
		DailySessions
	},
	methods:{
		onRangeSelected(e)
		{
			this.setSelectedDateRange(e.target.value)
		},
		setSelectedDateRange(val)
		{
			this.selectedRange = val;
			let p; let n = new Date(); // moment(now).subtract(24, 'h').toDate();
			switch(val){
				case 'p24' : p = new Date(n.getTime() - (24 * 60 * 60 * 1000)); break;
				case 'p7d' : p = new Date(n.getTime() - (24 * 60 * 60 * 1000 * 7)); break;
				case 'p4w' : p = new Date(n.getTime() - (24 * 60 * 60 * 1000 * 28)); break;
				case 'p6m' : p = new Date(); p.setMonth(p.getMonth() - 6); break;
			// past 24 hours //
				default : p = new Date(n.getTime() - (24 * 60 * 60 * 1000)); break;
			}
			this.sdate.setMaxDate(n);
			this.sdate.setDate(p, true);
			this.edate.setDate(n, true);
		}
	},

	mounted(){
		let oneYearAgo = new Date(); oneYearAgo.setYear(oneYearAgo.getFullYear() - 1);
	//	https://stackoverflow.com/a/54907413/228315
		this.sdate = new Pikaday({
			field: document.querySelector('#sdate input'),
			format: 'MMMM D YYYY',
			maxDate: new Date(),
			minDate: oneYearAgo,
			onSelect: () => {
				this.selectedRange = 'cst';
				this.$refs.rangeSelect.value = 'cst';
			}
		});
		this.edate = new Pikaday({
			field: document.querySelector('#edate input'),
			format: 'MMMM D YYYY',
			maxDate: new Date(),
			minDate: oneYearAgo,
			onSelect: () => {
				var d = this.edate.getDate();
				if (d < this.sdate.getDate()) {
					this.sdate.setDate(d);
					this.sdate.setMaxDate(d);
				}
				this.selectedRange = 'cst';
				this.$refs.rangeSelect.value = 'cst';
			}
		});
	// default to past 24 hours //
		this.setSelectedDateRange('p24');
	}

}

</script>

<style lang="stylus" scoped>
h4 {
	margin 16px 10px
	font-size 1rem
	font-weight 500
	font-family $roboto
	text-align left
}
#date-controls
	display flex
	.row
		flex 1
		display flex
		align-items flex-end
	.picker, select
		flex 1
		margin-right 1%
	.picker > label
		text-align left
	input, select, button
		height 40px
	select
		margin-bottom 0
	button
		width 120px

	@media (max-width: 800px)
		display block
		.row
			margin-bottom 1%
		.picker:last-child
			margin-right 0

</style>
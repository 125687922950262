
export default {
	data(){
		return {
			testdata : [{
				date: "2021-07-14T00:10:06.542Z",
				duration: 26.354,
				ip:{
					address: "localhost-1",
					city: "San Francisco",
					country: "United States",
					lat: "37.77116012573242",
					lng: "-122.43788146972656",
					region: "California",
					type: "ipv4"
				},
				pages: {
					path: "/", date: "2021-07-14T00:10:06.542Z", duration: 30.354
				}
			},
			{
				date: "2021-07-14T00:10:06.542Z",
				duration: 12.354,
				ip:{
					address: "localhost-2",
					city: "San Francisco",
					country: "United States",
					lat: "37.77116012573242",
					lng: "-122.43788146972656",
					region: "California",
					type: "ipv4"
				},
				pages: {
					path: "/projects", date: "2021-07-14T00:10:06.542Z", duration: 30.354
				}
			}]
		}
	}
}
<template lang="pug">
canvas#livetracker
</template>

<script>
export default{
	data(){
		return{
			points: [],
			cnvs: Object
		}
	},
	mounted(){
		let canvas = document.getElementById('livetracker');
		canvas.width = 150 * 2
		canvas.height = 190 * 2
		this.cnvs = {
			w : canvas.width,
			h : canvas.height,
			ctx : canvas.getContext("2d")
		}
		// this.cnvs.ctx.fillStyle = "blue";
		// this.cnvs.ctx.fillRect(0, 0, canvas.width, canvas.height);
		this.int = setInterval(this.attachPoint, 2500);
	},
	unmounted(){
		clearInterval(this.int);
	},
	methods:{
		attachPoint()
		{
			let p = {
				x : this.cnvs.w - 15,
				y : (Math.random() * (95)) + (95 / 2)
			}
			let r = 4; //radius //
			this.cnvs.ctx.clearRect(0, 0, this.cnvs.w, this.cnvs.h)
			this.cnvs.ctx.fillStyle = '#ffffff';
			this.cnvs.ctx.lineWidth = 2;
			this.cnvs.ctx.strokeStyle = '#ffffff';
			this.points.push(p);
			this.points.forEach((p, i) => {
				p.x -= 30;
				this.cnvs.ctx.beginPath();
				this.cnvs.ctx.arc(p.x, p.y, r, 0, 2 * Math.PI, false);
				this.cnvs.ctx.fill();
				if (this.points[i-1]){
					let k = this.points[i-1];
					this.cnvs.ctx.beginPath();
					this.cnvs.ctx.moveTo(k.x, k.y);
					this.cnvs.ctx.lineTo(p.x, p.y);
					this.cnvs.ctx.stroke();
				}
				if (p.x < 0) this.points.splice(i, 1);
			});
			this.cnvs.ctx.font = "bold 20pt Courier";
			this.cnvs.ctx.textAlign = "right";
			this.cnvs.ctx.textBaseline = 'top'
			let max = 10; let min = 0;
			for (let i = max; i > min - 1; i--) {
				this.cnvs.ctx.fillText(i, this.cnvs.w - 10, (max-i) * 33);

			}
		}
	}
}
</script>

<style lang="stylus" scoped>
#livetracker
	width : 150px;
	height : 190px;
	left 0; bottom 0
	position absolute
</style>



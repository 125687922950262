<template lang='pug'>
.widget
	h4 Unique Visitors – Chart
	.canvas-wrap
		canvas#uniqueVisitors
</template>

<script>

import Utils from '../mixins/Utils';
import BarChart from '../charts/BarChart'

export default{

	mixins: [BarChart, Utils],

	async mounted(){
		this.initChart('uniqueVisitors');
		await fetch('/api/v1/sessions?pid='+this.$route.params.key+'&groupby=ip&details=true&geo=true&past=7')
		.then(response => response.json())
		.then(property => {
			let labels = [];
			let values = [];
			let inserts = [];
			property.sessions.sort((a, b) => {
				return b.sessions.length - a.sessions.length;
			});
		// just show top ten for now //
			property.sessions.slice(0, 10).forEach(visitor => {
				labels.push(visitor.ip.address);
				values.push(visitor.sessions.length);
				inserts.push(this.location(visitor.ip));
			});
			this.renderChart(labels, values, inserts);
		});
	},
	methods:{
		onChartClicked(index){
			console.log('bar chart clicked at index:', index);
		},
	}

}

</script>

<style lang="stylus" scoped>
.canvas-wrap
	width 100%
	height 30vh
	position relative
</style>
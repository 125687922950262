<template lang='pug'>
.page-wrap
	.property
		form
			.form-group
				label Property URL
				input.form-control(type='text' disabled v-model='property.url')

			.form-group
				label Nickname
				input.form-control(type='text', v-model='property.name' @change='onNicknameEdited')

			.form-group
				label Contact Email
				input.form-control(type='email', disabled v-model='property.email')

			.form-group
				label Tracking Code
				textarea(disabled v-model='trackingCode')

			button.btn(@click='onDeleteProperty') Delete Property

</template>

<script>

export default{
	data(){
		return{
			property:{name:''}
		}
	},
	async mounted(){
		const res = await fetch('/api/v1/admin', {credentials: 'include'})
		.then(response => response.json())
		.then(response => {
			response.properties.forEach(p => {
				if (p.key === this.$route.params.key) this.property = p;
			});
			this.property.email = response.admin.email;
		});
	// prevent enter key from submitting form //
		document.getElementsByTagName('form')[0].addEventListener('keypress', (e) => {
			if (e.key === 'Enter') e.preventDefault();
		});
	},
	methods:{
		async onNicknameEdited(){
			const response = await fetch('/api/v1/property/update', {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				credentials: 'include',
				body: JSON.stringify({key:this.property.key, name:this.property.name})
			});
		},
		onDeleteProperty(e){
			e.preventDefault();
			if (confirm('Are you absolutely sure you want to delete this property?')){
				console.log('deleting property');
			};
		}
	},
	computed:{
		trackingCode(){
			let str = '<script src="https://analytics.braitsch.io/client.js"<\/script>';
				str+= '<script>initConnection("'+this.property.key+'");<\/script>'
			return str;
		}
	}
}

</script>

<style lang='stylus'>
form
	margin 40px auto
	padding 34px 24px 20px 24px
	max-width 540px
	border-radius 5px
	border 1px solid #ccc
	.form-group
		margin-bottom: 1.8vh;
	label
		text-align left
	.invalid-feedback
		display none
	.btn
		margin-top 14px
		background-color red
	textarea
		resize none

</style>